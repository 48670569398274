.profile-picture {
  box-shadow: 0px 0px 0px 0px white;
  cursor: pointer;
  transition: box-shadow 0.6s linear;
  user-select: none;

  &:hover {
    box-shadow: 0px 0px 0px 3px #ecececaf;
  }
}
