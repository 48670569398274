@import '../../../assets/sass/theme.scss';

.modal {
  .body {
    max-height: calc(90vh - 210px);
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 0.5em;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba($primary-color, 0.3);
    }

    &::-webkit-scrollbar-thumb {
      background-color: $primary-color;
      outline: 1px solid slategrey;
    }
  }

  ol li {
    font-weight: bold;
  }

  li > p {
    font-weight: normal;
  }
}
