@import '../../../assets/sass/utils.scss';

video {
  @extend %mirrored;
  width: 100%;
  display: block;
  margin: 0 auto;
}

.participant {
  .audio-control {
    display: none;
    position: absolute;
    cursor: pointer;
    top: 5px;
    pointer-events: all;
    left: 5px;
    z-index: 100;
  }

  .video-control {
    display: none;
    position: absolute;
    cursor: pointer;
    top: 25px;
    pointer-events: all;
    left: 5px;
    z-index: 100;
  }

  .video-device-control {
    display: none;
    position: absolute;
    cursor: pointer;
    top: 5px;
    pointer-events: all;
    right: 5px;
    z-index: 100;
  }

  &:hover {
    .audio-control {
      display: unset;
    }

    .video-control {
      display: unset;
    }

    .video-device-control {
      display: unset;
    }
  }
}
