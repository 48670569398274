.waiting-message-container {
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  pointer-events: none;

  .waiting-message-icon {
    width: calc(5rem + 5vmin);
    height: calc(5rem + 5vmin);
    margin-bottom: 2rem;
  }

  .waiting-message-description {
    font-size: 1rem;
    user-select: none;
  }

  .waiting-message-code {
    font-size: 2rem;
    font-weight: bold;
    margin-left: 3rem;
    pointer-events: visible;
    cursor: pointer;

    > div {
      user-select: none;
    }

    > svg {
      opacity: 0;
      transition: all ease-in 0.25s;
    }

    &:hover > svg {
      opacity: 1;
    }
  }
}
