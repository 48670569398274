@import '../../../assets/sass/animations.scss';
@import '../../../assets/sass/theme.scss';

$yellow: #ffe800;
$brown: #404040;
$blue: #1cd3f8;
$angle: 8px;
$angle-o: 4px;

.lobby {
  @include animation('fadein 1s');

  .appointment-code-input {
    input {
      font-weight: bold;
      font-size: calc(0.25em + 2vmax);
    }
  }

  .code-prompt {
    user-select: none;
  }

  .submit-button {
    background: linear-gradient(
      222deg,
      lighten($primary-color, 14%) 0%,
      $primary-color 100%
    );

    color: #fff;
    text-decoration: none;
    background-color: #0094ff;
    font-weight: bold;
    display: block;
    border-radius: 0.42rem;
    box-shadow: 0px 3px 0px #1672b5, 0px 3px 7px #1672b5;
    text-align: center;
    margin-bottom: 3px;
    @include transition(all 0.2s ease);

    &:active {
      position: relative;
      top: 6px;
      box-shadow: 0px 1px 0px #095e9c, 0px 3px 10px #095e9c;
      background: linear-gradient(
        220deg,
        lighten($primary-color, 27%) 0%,
        $primary-color 100%
      ) !important;
    }

    width: 6em;
  }
}
