@import '../../assets/sass/theme.scss';

.content {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: calc(0.5rem + 2vmin);

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }
}

.icon {
  font-size: 13rem;
  margin: 3rem;
}

.message {
  display: inline-block;
  margin-left: 1rem;
}

.prefix {
  display: inline-block;
  font-weight: bold;

  @include media-breakpoint-down(sm) {
    margin-bottom: 0.5rem;
  }
}
