@import '../../../assets/sass/animations.scss';
@import '../../../assets/sass/theme.scss';

.devicePreview {
  @include animation('fadein 1s');
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: $primary-color;
  background: radial-gradient(
    circle,
    $primary-color,
    darken($primary-color, 14%) calc(1rem + 64vmin)
  );
  max-height: 100%;
  width: 100vw;
  height: 100vh;

  .appoinmentActions {
    display: flex;
    max-width: 50vmax;
    width: calc(100% - 2.2rem);
    justify-content: space-evenly;
    .cancelButton {
      margin-right: 1rem;
      width: 6rem;
    }

    .joinButton {
      margin-left: 1rem;
      width: 69%;
    }
  }

  .cancelButton {
    z-index: 1000;
    position: absolute;
    top: 1rem;
    padding: 0;
    right: 1rem;

    border-radius: 50%;
    -webkit-transition: -webkit-transform 0.8s ease-in-out;
    transition: transform 0.8s ease-in-out;

    &:hover {
      @extend %rotate90;
    }
  }
}

.videoContainer {
  position: relative;
  display: flex;
  align-items: flex-end;
  border: 1px solid transparent;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  margin-bottom: 3rem;
  outline: 0;
  border-radius: 0.3rem;
  max-width: 50vmax;
  width: calc(100% - 2.2rem);
  height: 50%;
  box-shadow: -0.1rem 1rem 2rem -0.1rem rgba(0, 0, 0, 0.54);

  .video {
    @extend %mirrored;
    background-color: #292828;
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
    display: block;
    margin: 0 auto;
    border-radius: 0.3rem;
    border: 1px solid transparent;
  }

  .toolbar {
    display: flex;
    justify-content: space-between;
    margin: 1rem;
    width: 100%;

    @include media-breakpoint-down(sm) {
      justify-content: center;
    }

    .controls {
      display: flex;
      justify-content: space-evenly;

      .audioControl {
        display: flex;
        align-items: center;
        cursor: pointer;
        pointer-events: all;
        z-index: 100;
        font-size: 2.2rem;
        margin-right: 1.5rem;

        .audioIcon {
          z-index: 101;
          height: 80%;
          margin-right: 0.5rem;
        }
      }

      .videoControl {
        display: flex;
        align-items: center;
        cursor: pointer;
        pointer-events: all;
        z-index: 100;
        font-size: 2.2rem;

        .videoIcon {
          z-index: 101;
          height: 80%;
          margin-right: 0.5rem;
        }
      }
    }

    .changeVideoDevice {
      position: absolute;
      top: 1rem;
      right: 1rem;
      background-color: rgba(0, 0, 0, 0.42);
      border-radius: 50%;
      padding: 0;
      display: flex;
      align-items: center;
      cursor: pointer;
      pointer-events: all;
      z-index: 100;
      font-size: 2.2rem;

      .changeVideoDeviceIcon {
        margin: 0.1em;
        color: white;
        z-index: 101;
      }
    }
  }
}

.videoToolbarGradient {
  position: absolute;
  width: 100%;
  height: 50%;
  bottom: 0;
  background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.84));
  z-index: 2;
  border-radius: 0.3rem;
}
